import React from "react";
import { makeStyles, Box, Container, Grid, Typography } from "@material-ui/core";
import person1 from '../../../../images/person1.png';
import person10 from '../../../../images/mustafa.png';
import person2 from '../../../../images/person2.png';
import person3 from '../../../../images/person3.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "black",
    paddingLeft: "50px",
    paddingRight: "44px",
    paddingBottom: "34px",
    paddingTop: "20px",
    position: "relative",
    zIndex: "999",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& figure": {
      margin: "0px",
    },
  },
  MetaverseTextBox: {
    color: "white",
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    color: "white",
    marginBottom: "20px",
  },
  ImageBox: {
    "& figure": {
      margin: "0px",
    },
  },
  grid1: {
    color: "white",
    order: "2",
    [theme.breakpoints.down("xs")]: {
      order: "1",
    },
  },
  grid2: {
    order: "2",
    [theme.breakpoints.down("xs")]: {
      order: "1",
    },
  },
}));

function CustomBlockchain() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} className={classes.grid1}>
            <Box className={classes.MetaverseTextBox}>
              <Typography variant="h2" style={{ paddingTop: "50px" }}>Our Founder</Typography>
              <Typography
                variant="body2"
                style={{
                  fontSize: "18px",
                  color: "rgba(255, 255, 255, 0.6)",
                  fontFamily: "SF Pro Display, sans-serif",
                  fontWeight: "400",
                  lineHeight: "25px",
                }}
              >
               Meet the passionate innovators and experts driving SST’s mission to protect assets and promote sustainability.
              </Typography>
            </Box>
          </Grid>

          {/* Team Members */}
          <Grid container spacing={2} className={classes.grid2}>
            {/* First Team Member */}
            <Grid item xs={12} md={3} sm={6} className={classes.gridItem}>
              <img src={person10} width="100%" alt="Photo 1" />
              <Box>
                <Typography variant="h4" style={{ fontWeight: "bold" }}>
                Mustafa Hashmi
                </Typography>
                <Typography variant="h5">
                Founder & CEO 
                </Typography>
              </Box>
            </Grid>

            {/* Other Team Members */}
            {/* <Grid item xs={12} md={3} sm={6} className={classes.gridItem}>
              <img src={person2} width="100%" alt="Photo 2" />
              <Box>
                <Typography variant="h4" style={{ fontWeight: "bold" }}>
                Iza Audrey
                </Typography>
                <Typography variant="h5">
                  Chief Scientific Officer
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sm={6} className={classes.gridItem}>
              <img src={person3} width="100%" alt="Photo 3" />
              <Box>
                <Typography variant="h4" style={{ fontWeight: "bold" }}>
                William henrey
                </Typography>
                <Typography variant="h5">
                  Chief Scientific Officer
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sm={6} className={classes.gridItem}>
              <img src={person1} width="100%" alt="Photo 4" />
              <Box>
                <Typography variant="h4" style={{ fontWeight: "bold" }}>
                John Doe
                </Typography>
                <Typography variant="h5">
                  Chief Scientific Officer
                </Typography>
              </Box>
            </Grid> */}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default CustomBlockchain;
