import React from "react";
// import TopBar from "../../../../layouts/HomeLayout/TopBar.js";
// import Footer from "../../../../layouts/HomeLayout/Footer.js";
import { Button } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import {
  makeStyles,
  Box,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F9F9F9",
    position: "relative",
    zIndex: "999",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& figure": {
      "& img": {
        maxWidth: "700px",
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
      },
    },
    "& .maincontentBox": {
      // backgroundImage: "url(images/devopsbanner.png)",
      // backgroundSize: "cover",
      backgroundColor: "F9F9F9",

      backgroundRepeat: "no-repeat",
      width: "100%",

      backgroundPosition: "right",
      [theme.breakpoints.down("sm")]: {
        backgroundPosition: "left",
      },
    },
  },
  // container: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   height: '100vh', // Full height of the viewport
  //   backgroundColor: '#f0f0f0', // Optional: background color for visibility
  // },
  BlockchainTextBox: {
    fontFamily: "SF Pro Display, sans-serif",
    padding: "25px 284px",
    margin: "50px 50px",
    // padding: "theme.spacing(2)",
    alignItems: "center",
    alignItems: "flex-start",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(4),
      // textAlign: "left",
    },
    // flexDirection: "column",
    // "& h1": {},
    // "& h6": {},
    // [theme.breakpoints.down("xs")]: {
    //   padding: "50px 0px 50px",
    // },
    // display: "flex",
  },
  aboutUsHeading: {
    color: "#232061", // Set the color to #232061
  },
}));

// const DownloadButton = () => {
const handleDownload = () => {
  // Logic for downloading a file
  const link = document.createElement("a");
  link.href = "path_to_your_file"; // Specify the file path here
  link.download = "filename.ext"; // Specify the file name and extension here
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function Banner() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className="maincontentBox">
        <Grid container spacing={2} alignItems="center">
          <Grid>
            <Box className={classes.BlockchainTextBox}>
              <Typography variant="h3" className={classes.aboutUsHeading}>
                About us
              </Typography>
              <Typography variant="h2">
                Smart Leak Detection
              </Typography>
              <Box my={1}>
                <Typography variant="body2">
                  A ClimateTech company offering smart sensing and monitoring
                  solutions to protect mission critical assets indoors and
                  prevent exposure of toxic fluids to environment outdoors.<br></br> A
                  Technological company offering smart liquid leakage detection
                  solution to save water, environment and critical assets. First
                  and only company in KSA & GCC to localize state-of-the-art
                  liquid sensing technology provider in a rapidly growing 
                  Billions of dollar market. <br></br> A solution developed to address the regional
                  environmental challenges and local  Mission Critical/Data Center applications.
                </Typography>
              </Box>
              {/* <Button className="btn"
      variant="contained"
      color="secondary"
      startIcon={<GetApp />}
      onClick={handleDownload}
      // style={{ backgroundColor: '#FF69B4', color: '#FFFFFF' }}
    >
      Company Profile
    </Button> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Banner;
