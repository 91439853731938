import React from "react";
import { Button, Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import img1 from "../../../../images/img1.png";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "50px 53px",
  },
  ImageBox: {
    "& figure": {
      margin: "10px",
      overflow: "hidden",
      "& img": {
        width: "100%",
        height: "auto",
        transition: "0.5s",
      },
    },
  },
  BlockchainTextBox: {
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "20px",
    margin: "89px 0px",
    textAlign: "left",
    justifyContent: "center",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(4),
    },
  },
}));

const handleOpen = () => {
  const link = document.createElement("a");
  link.href = "path_to_your_file"; // Specify the file path here
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function Metaverse() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Grid container spacing={5} alignItems="center">
        {/* Left Side: Company Information */}
        <Grid item xs={12} md={6}>
          <Box className={classes.BlockchainTextBox}>
            <Typography variant="h2"> Our Company Information</Typography>
            <Box my={1}>
              <Typography variant="body2">
              Saudi Sustainability Technologies (SST) is a Climate-Tech company pioneering smart sensing solutions for leak detection. As the first company in Saudi Arabia to localize advanced liquid sensing technology, SST designs,developes and manufactures advanced solutions meeting cutting edge requirement as per latest international standard, specification,certification to protect critical assets, supporting sustainability and contributing to Vision 2030 in making Saudi Arabia the world leader in smart and sustinable solutions. 
              </Typography>
            </Box>
            {/* <Box>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleOpen}
              >
                Explore in 3D
              </Button>
            </Box> */}
          </Box>
        </Grid>

        {/* Right Side: Image */}
        <Grid item xs={12} md={6}>
          {/* <Box className={classes.ImageBox}>
            <figure>
              <img
                src={img1}
                alt="Company Overview"
                width="100%"
                height="auto"
              />
            </figure>
          </Box> */}
             <Box className={classes.ImageBox}>
              <video  src="https://res.cloudinary.com/dtztsfm99/video/upload/v1720681360/LoadingPage_bkjr11.mp4"
               autoPlay
               muted
               loop
               preload="auto"
               playsInline
              />
            </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Metaverse;
