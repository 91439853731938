import React from 'react';
import { makeStyles, Box, Typography,} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "25px",
    },
    styleClass0: {
        backgroundColor: "#F4F4F4",
        color: "#000000",
        borderRadius: "20px",
        height: "100%",
        "& .imageBox": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            "& h1": {
                marginLeft: "25px",
            },
            "& figure": {
                margin: "0px",
                "& img": {

                }
            }
        },
        "&:hover": {
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        },
    },

    styleClass1: {
        backgroundColor: "#F4F4F4",
        borderRadius: "20px",
        color: "#000000",
        height: "100%",
        "& .imageBox": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            "& h1": {
                marginLeft: "25px",
            },
            "& figure": {
                margin: "0px",
                "& img": {

                }
            }
        },
        "&:hover": {
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        },
    },
    styleClass2: {
        backgroundColor: "#F4F4F4",
        borderRadius: "20px",
        color: "#000000",
        height: "100%",
        "& .imageBox": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            "& h1": {
                marginLeft: "25px",
            },
            "& figure": {
                margin: "0px",
                "& img": {

                }
            }
        },
        "&:hover": {
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        },
    },
    styleClass3: {
        backgroundColor: "#F4F4F4",
        borderRadius: "20px",
         color: "#000000",
        height: "100%",
        "& .imageBox": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            "& h1": {
                marginLeft: "25px",
            },
            "& figure": {
                margin: "0px",
                "& img": {

                }
            }
        },
        "&:hover": {
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        },
    },
    styleClass4: {
        backgroundColor: "#F4F4F4",
        borderRadius: "20px",
        color: "#000000",
        height: "100%",
        "& .imageBox": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            "& h1": {
                marginLeft: "25px",
            },
            "& figure": {
                margin: "0px",
                "& img": {

                }
            }
        },
        "&:hover": {
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        },
    },
    styleClass5: {
        backgroundColor: "#F4F4F4",
        borderRadius: "20px",
        color: "#000000",
        height: "100%",
        "& .imageBox": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            "& h1": {
                marginLeft: "25px",
            },
            "& figure": {
                margin: "0px",
                "& img": {

                }
            }
        },
        "&:hover": {
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        },
    },
    styleClass6: {
        backgroundColor: "#F4F4F4",
        borderRadius: "20px",
        color: "#000000",
        height: "100%",
        "& .imageBox": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            "& h1": {
                marginLeft: "25px",
            },
            "& figure": {
                margin: "0px",
                "& img": {

                }
            }
        },
        "&:hover": {
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        },
    },
    styleClass7: {
        backgroundColor: "#F4F4F4",
        borderRadius: "20px",
        color: "#000000",
        height: "100%",
        "& .imageBox": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            "& h1": {
                marginLeft: "25px",
            },
            "& figure": {
                margin: "0px",
                "& img": {

                }
            }
        },
        "&:hover": {
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        },
    },
    timeText: {
        position: "absolute",
        fontsize: "11px",
        right: "15px",
        bottom: "15px"
    },
    title: {
        fontSize: "30px",
        fontWeight: "600", // Bold for the title
      },
      description: {
        fontSize: "15px",
        fontWeight: "normal", // Ensure normal weight for description
        color: "rgba(0, 0, 0, 0.7)",
        whiteSpace: "pre-line", // Handle newlines in the description text
        overflow: "hidden", // Hide overflow text
        textOverflow: "ellipsis", // Add ellipsis (...) for overflowing text
      },
}));

function BlockchainCard({ data, index }) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Box className={`${index}` === "0" ? classes.styleClass0 : `${index}` === "1" ? classes.styleClass1 : `${index}` === "2" ? classes.styleClass2 : `${index}` === "3" ? classes.styleClass0 : `${index}` === "4" ? classes.styleClass0 : `${index}` === "5" ? classes.styleClass1 : `${index}` === "6" ? classes.styleClass1 : classes.styleClass3} >
            <Box className={classes.root} >

            <Box className='imageBox'>
                    <figure>
                    <img src={data.images} alt="medal" style={{ width: "30%", height: "30%" }} />
                    </figure>
                </Box>
            <Typography className={classes.title}>{data.title}</Typography><br />
      <Typography className={classes.description}>
        {/* Split the description into multiple lines */}
        {data.description.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </Typography>


              
                {/* <Box my={2}>
                    <Typography variant='h2' sx={{ fontWeight: "normal" }}>{data?.title ? <>{`${data?.title} `}</> : " "}</Typography>
                </Box>
                <Box my={2}>
                    <Typography variant='h4' sx={{ fontWeight: "normal" }}>{data?.subheading ? <>{`${data?.subheading} `}</> : " "}</Typography>
                </Box>
                <Box className={classes.subHeading}>
                    <Typography variant="body2" sx={{ fontWeight: "normal" }}>
                        {data?.description ? <>{`${data?.description} `}</> : " "}
                    </Typography>
                </Box>
                <Box my={1}>
                    <Typography variant="h4" sx={{ fontWeight: "normal" }}>
                        {data?.subheading2 ? <>{`${data?.subheading2} `}</> : " "}
                    </Typography>
                </Box>
                <Box className={classes.subHeading}>
                    <Typography variant="body2" sx={{ fontWeight: "normal" }}>
                        {data?.description2 ? <>{`${data?.description2} `}</> : " "}
                    </Typography>
                </Box> */}
            </Box>
        </Box>
    )
}

export default BlockchainCard;