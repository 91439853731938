// import React from 'react';
// import Timeline from '@material-ui/lab/Timeline';
// import TimelineItem from '@material-ui/lab/TimelineItem';
// import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
// import TimelineConnector from '@material-ui/lab/TimelineConnector';
// import TimelineContent from '@material-ui/lab/TimelineContent';
// import TimelineDot from '@material-ui/lab/TimelineDot';

// export default function AlternateTimeline() {
//   return (
//     <Timeline align="alternate">
//       <TimelineItem>
//         <TimelineSeparator>
//           <TimelineDot />
//           <TimelineConnector />
//         </TimelineSeparator>
//         <TimelineContent>Eat</TimelineContent>
//       </TimelineItem>
//       <TimelineItem>
//         <TimelineSeparator>
//           <TimelineDot />
//           <TimelineConnector />
//         </TimelineSeparator>
//         <TimelineContent>Code</TimelineContent>
//       </TimelineItem>
//       <TimelineItem>
//         <TimelineSeparator>
//           <TimelineDot />
//           <TimelineConnector />
//         </TimelineSeparator>
//         <TimelineContent>Sleep</TimelineContent>
//       </TimelineItem>
//       <TimelineItem>
//         <TimelineSeparator>
//           <TimelineDot />
//         </TimelineSeparator>
//         <TimelineContent>Repeat</TimelineContent>
//       </TimelineItem>
//     </Timeline>
//   );
// }



import React from 'react';
import { Card, CardContent, Typography, CardMedia } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

export default function AlternateTimeline() {
  const events = [
    {
      images: require("../../../../images/medal.png"), // Ensure these image paths are correct
      title: "July 2023",
      description: "Graduated Misk – Spark 7",
    },
    {
      images: require("../../../../images/medal.png"),
      title: "December 2023",
      description: `Secured a win at Misk Launchpad 5.0, solidifying our innovative approach.`,
    },
    {
      images: require("../../../../images/medal.png"),
      title: " February 2024",
      description: `Obtained the official MISA License, enhancing our operational credibility.`,
    },
    {
      images: require("../../../../images/medal.png"),
      title: " March 2024",
      description: "- Launch Rival Banking services in Lebanon - Launch Card Programs - Launch Stocks and Crypto Investing",
    },
    {
      images: require("../../../../images/medal.png"),
      title: "May 2024",
      description: "Should be as a time-lines with the logos and graphical representation",
    },
    {
      images: require("../../../../images/medal.png"),
      title: " December 2024",
      description: `coming soon`,
    },
  ];

  const renderCard = (event) => (
    // <Card variant="outlined">
    //   <CardMedia
    //     component="img"
    //     alt={event.title}
    //     height="140"
    //     image={event.images}
    //     style={{ objectFit: "contain" }} // Optional: Adjust the style as needed
    //   />
      <CardContent>
        <Typography variant="h6" component="div">
          {event.title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {event.description}
        </Typography>
      </CardContent>
    // </Card>
  );

  return (
    <Timeline align="alternate">
      {events.map((event, index) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot />
            {index < events.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>{renderCard(event)}</TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}

