import React from "react";
import {
  Grid,
  Box,
  Typography,
  List,
  ListItem,
  makeStyles,
  TextField,
  Button,
  FormHelperText,
  Link,
  Divider,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Link as RouterLink } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import logo from "../../../../images/logo.png";
import { FaWhatsapp } from "react-icons/fa";


const useStyles = makeStyles((theme) => ({
  footerSection: {
    padding: "11px",
    backgroundColor: "#232061",
    paddingTop: theme.spacing(4),
    "& h3": {
      fontWeight: "600",
      fontSize: "18px",
      color: "rgba(255, 255, 255, 0.87)",
      marginBottom: "0px",
      margin: "0px",
      textAlign: "left",
    },
    "& .subpart": {
      backgroundColor: "#232061",
      paddingLeft: "57px",
      paddingRight: "57px",
      paddingTop: "53px",
      "& h6": {
        fontSize: "12px",
        color: "gray",
        fontWeight: "300",
        lineHeight: "19px",
      },
    },
  },
  icon: {
    cursor: "pointer",
    color: "white",
    background: "#3A3878",
    marginRight: "10px",
    marginBottom: "18px",
    fontSize: "18px",
    padding: "6px",
    borderRadius: "25px",
    "&:hover": {
      color: "#3DA1E5",
    },
  },
  emailIcon: {
    marginRight: "10px",
    marginTop: "8px",
    color: "rgba(255, 255, 255, 0.87)",
  },
  textBtn: {
    color: "white",
    width: "100%",
    fontSize: "14px",
    lineHeight: "21px",
    padding: "10px 10px !important",
    background: "#DF4398",
    borderRadius: "50px",
    fontWeight: "500",
    marginTop: "10px",
  },
  listbox: {
    margin: "0px",
    "& a": {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "14px",
      lineHeight: "26px",
      color: "rgba(255, 255, 255, 0.75)",
      "&:hover": {
        color: "#3DA1E5",
      },
    },
  },
  listboxcontact: {
    margin: "0px",
    "& a": {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "14px",
      lineHeight: "26px",
      color: "#FFFFFF",
      "&:hover": {
        color: "#3DA1E5",
      },
    },
  },
  feildBox: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
    },
  },
  logoimg: {
    color: "white",
    fontSize: "20px",
  },
  baseSection: {
    padding: "37px 68px",
  },
  gridItem: {
    paddingLeft: theme.spacing(2), // Adjust to move grids to the right
  },
  privacyPolicy: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "128px", // Aligns with "All Rights Reserved." on mobile view
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "87px", // Position for desktop view
      paddingTop: "24px",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();

  return (
    <Box className={classes.footerSection}>
      <Box className="subpart">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Box className={classes.logoimg}>
              <img
                src={logo}
                alt=""
                width="100%"
                style={{
                  maxWidth: "170px",
                  margin: "0 auto",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box mt={1} mb={2}>
              <Typography
                variant="body2"
                style={{
                  color: "rgba(255, 255, 255, 0.6)",
                  fontWeight: "normal",
                  fontSize: "15px",
                  paddingLeft: "0px",
                }}
              >
                A ClimateTech company offering smart sensing and monitoring
                solutions to protect mission critical assets indoors and prevent
                exposure of toxic fluids to environment outdoors.
              </Typography>
            </Box>
            <Box>
              {/* <Link
                href="https://www.facebook.com/company/saudisustain"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF className={classes.icon} />
              </Link> */}
              <Link
                href="https://www.linkedin.com/company/saudisustain"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn className={classes.icon} />
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Typography variant="h3">Quick Links</Typography>
            <List className={classes.listbox}>
              <RouterLink to="/partnerwithus">
                <ListItem>Partner with us</ListItem>
              </RouterLink>
              <RouterLink to="/ourproducts">
                <ListItem>Work with us</ListItem>
              </RouterLink>
              {/* <RouterLink to="/contact">
                <ListItem>Contact us</ListItem>
              </RouterLink> */}
              <RouterLink to="/cloudsoftware">
                <ListItem>Cloud Software / Product Registration</ListItem>
              </RouterLink>
            </List>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Typography variant="h3">Company</Typography>
            <List className={classes.listbox}>
              <RouterLink to="/aboutus">
                <ListItem>About us</ListItem>
              </RouterLink>
              {/* <RouterLink to="/terms">
                <ListItem>Our Projects</ListItem>
              </RouterLink> */}
              <RouterLink to="/invest">
                <ListItem>Invest With Us</ListItem>
              </RouterLink>
            </List>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Typography variant="h3">Contact Us</Typography>
            <List className={classes.listboxcontact}>
              {/* <ListItem>
                <MdEmail className={classes.emailIcon} />
                <RouterLink to="/aboutus">support@sst.com</RouterLink>
              </ListItem> */}
              <ListItem>
                <MdEmail className={classes.emailIcon} />
                <a
                  component="a"
                  href="mailto:hello@saudisustain.com"
                  target="_self"
                  rel="noopener noreferrer"
                >
                  hello@saudisustain.com
                </a>
              </ListItem>

              {/* <ListItem>
                <MdEmail className={classes.emailIcon} />
                <RouterLink to="/aboutus">support@sst.com</RouterLink>
              </ListItem> */}
              
              <ListItem>
                <FaWhatsapp className={classes.emailIcon} />
                <a
                  href="https://wa.me/1234567890" // Replace with the actual phone number
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  +966-501157060
                </a>
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Formik
              onSubmit={(values, { resetForm }) => {
                resetForm();
              }}
              initialValues={{
                email: "",
              }}
              validationSchema={yup.object().shape({
                email: yup
                  .string()
                  .email(
                    "You have entered an invalid email address. Please try again"
                  )
                  .required("Email address is required."),
              })}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
              }) => (
                <Form>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box>
                      <Typography
                        variant="h3"
                        style={{
                          marginBottom: "10px",
                          color: "white",
                          fontSize: "25px",
                        }}
                      >
                        Subscribe for our Newsletter
                      </Typography>
                      <Box className={classes.feildBox}>
                        <TextField
                          placeholder="Enter your email address"
                          className={classes.textFeild}
                          name="email"
                          variant="outlined"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                        />
                        <FormHelperText error>
                          {touched.email && errors.email}
                        </FormHelperText>
                      </Box>
                      <Button
                        variant="contained"
                        type="submit"
                        color="secondary"
                        className={classes.textBtn}
                        fullWidth
                      >
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
      <Divider style={{ background: "rgba(255, 255, 255, 0.1)" }} />
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        style={{ paddingTop: "20px" }}
      >
        <Grid item xs={12} sm={8}>
          <Typography
            variant="body2"
            className={classes.privacyPolicy}
            style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "15px" }}
          >
            2024 All Rights Reserved.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            variant="body2"
            className={classes.privacyPolicy}
            style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "15px" }}
          >
            Privacy Policy | Terms & Condition
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
