import React from "react";
import {
  makeStyles,
  Box,
  Grid,
  Container,
  Typography,
} from "@material-ui/core";
import BlockchainCard from "src/component/BlockchainCard"; // Make sure this path is correct
import Timeline from "../VD Exchange/Timeline"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px 44px 70px",
    backgroundColor: "#fff",
    [theme.breakpoints.down("xs")]: {
      padding: "50px 10px",
    },
  },
  MainContentBox: {
    height: "100%",
    "& .innerContentBox": {
      backgroundColor: "#000000",
    },
  },
  heading: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  description: {
    fontSize: "18px",
    padding: "0px 220px",
    paddingBottom: "15px",
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "SF Pro Display, sans-serif",
    fontWeight: "400",
    lineHeight: "25px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 20px", // Adjust padding for mobile view
    },
  },
}));

const blockchainData = [
  {
    images: require("../../../../images/medal.png"), // Ensure these image paths are correct
    title: "July 2023",
    description: "Graduated Misk – Spark 7",
  },
  {
    images: require("../../../../images/medal.png"),
    title: "December 2023",
    description: `Secured a win at Misk Launchpad 5.0, solidifying our innovative approach.`,
  },
  {
    images: require("../../../../images/medal.png"),
    title: " February 2024",
    description: `Obtained the official MISA License, enhancing our operational credibility.`,
  },
  {
    images: require("../../../../images/medal.png"),
    title: " March 2024",
    description: "- Launch Rival Banking services in Lebanon - Launch Card Programs - Launch Stocks and Crypto Investing",
  },
  {
    images: require("../../../../images/medal.png"),
    title: "May 2024",
    description: "Should be as a time-lines with the logos and graphical representation",
  },
  {
    images: require("../../../../images/medal.png"),
    title: " December 2024",
    description: `coming soon`,
  },
];

function Metaverse() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Container>
        <Box align="center" p={3} className={classes.heading}>
          <Typography variant="h2">Our Milestone So far</Typography>
          <Typography className={classes.description}>
            Milestones achieved and ambitions to revolutionize leak detection technology worldwide
          </Typography>
        </Box>
        <>

        {/* <Grid container spacing={1}>
          {blockchainData &&
            blockchainData.map((data, index) => (
              <Grid item xs={12} md={4} key={index}>
                <BlockchainCard data={data} index={index} />
              </Grid>
            ))}
        </Grid> */}
        </>
      </Container>
      <Timeline />
    </Box>
  );
}

export default Metaverse;
